import Section from '../Section'
import { RightArrow } from '../../svg/RightArrow'
import useResponsive from '../../../sdk/responsive/useResponsive'
import './hightlightsection.scss'

type HighlightCard = {
  image: string
  cardTitle: string
  cardText: string
  cardLink: string
}

interface Props {
  titleText: string
  cards: HighlightCard[]
}

function HighlightsSection(props: Props) {
  const { titleText, cards } = props
  const { isPortrait } = useResponsive()
  const mobileClass = isPortrait ? 'highlightMobile' : ''

  return (
    <Section className={`${mobileClass} layout__content a-center j-center`}>
      <h4 className="partner-color-dark py-1 section-title my-1">
        {titleText}
      </h4>
      <div className="cards-wrapper flex flex-row j-center j-between">
        {cards.map((item, index) => {
          return (
            <article
              key={index}
              className="highlight-article w-33 mw-30-percent"
            >
              <img src={item.image} alt="Teste" className="w-100" />
              <h5 className="hightlight-title partner-color-dark py-1 line-heigth-140">
                {item.cardTitle}
              </h5>
              <p className="partner-color-neutral h-4 line-heigth-140 overflow-hidden">
                {item.cardText}
              </p>
              <a
                className="py-1 partner-color-pink no-decoration display-block"
                href={item.cardLink}
              >
                <div>
                  <p className="bold a-center flex w-100 a-start flex-row">
                    <span className="w-90-pixels">LEIA MAIS</span>
                    <RightArrow />
                  </p>
                </div>
              </a>
            </article>
          )
        })}
      </div>
    </Section>
  )
}

export default HighlightsSection
