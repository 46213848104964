import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import { useProductsQuery } from 'src/sdk/product/useProductsQuery'
import type { ProductsQueryQueryVariables } from '@generated/graphql'
import useResponsive from 'src/sdk/responsive/useResponsive'

import CustomCarousel from '../../ui/CustomCarousel'
import ProductCard from '../../product/ProductCard'
import Section from '../Section'

interface ProductShelfProps extends Partial<ProductsQueryQueryVariables> {
  title: string | JSX.Element
  withDivisor?: boolean
  suspenseData?: boolean
}

const options = {
  suspense: true,
  fallbackData: { search: { products: undefined } },
}

function ProductShelf({
  title,
  withDivisor = false,
  suspenseData,
  ...variables
}: ProductShelfProps) {
  const products = useProductsQuery(
    variables,
    suspenseData ? options : undefined
  )

  const { isPortrait } = useResponsive()
  const liWidth = isPortrait ? `w-370-pixels` : `w-305-pixels`

  if (products?.edges.length === 0) {
    return null
  }

  return (
    <Section
      className={`layout__section my-0 ${
        withDivisor ? 'section__divisor' : ''
      }`}
    >
      <h2 className="text__title-section layout__content">{title}</h2>
      <div data-fs-product-shelf>
        <ProductShelfSkeleton loading={products === undefined}>
          <CustomCarousel>
            {products?.edges.map((product, idx) => (
              <li className={` p-1 list-style-none`} key={`${product.node.id}`}>
                <div className={`${liWidth}`}>
                  <ProductCard product={product.node} index={idx + 1} />
                </div>
              </li>
            ))}
          </CustomCarousel>
        </ProductShelfSkeleton>
      </div>
    </Section>
  )
}

export default ProductShelf
