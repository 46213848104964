import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'

interface NewsletterProps {
  mainTitle: string
  buttonText: string
  inputPlaceholder: string
  optinText: string
}

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail é inválido')
    .required('Campo e-mail é obrigatório'),
  allowNewsletter: Yup.boolean().required('Opt-in é obrigatório'),
})

function Newsletter(props: NewsletterProps) {
  const { mainTitle, buttonText, inputPlaceholder, optinText } = props

  type Status = 'success' | 'error'

  const [status, setStatus] = useState<Status | null>()

  return (
    <section className="w-100 partner-color-light-gray bg-partner-color-dark b-bottom-gray">
      <div className="layout__content">
        <h5 className="t-center section-title">{mainTitle}</h5>
        <Formik
          initialValues={{
            email: '',
            allowNewsletter: false,
          }}
          validationSchema={ValidationSchema}
          onSubmit={async (values) => {
            await fetch('https://partnernetwork.myvtex.com/_v/newsletterlead', {
              method: 'POST',
              body: JSON.stringify(values),
            })
              .then(() => {
                setStatus('success')
              })
              .catch((err) => {
                console.info('ERRO', err)
                setStatus('error')
              })
          }}
        >
          {({ errors, touched }) => (
            <Form className="flex flex-col mw-750-px mx-auto">
              <div className="flex flex-row j-center a-center flex-wrap gap-1 py-2">
                <Field
                  id="email"
                  name="email"
                  placeholder={inputPlaceholder}
                  className="p-1 b-radius-5 b-none flex-1"
                />
                <button
                  type="submit"
                  className="p-1 bg-partner-color-pink b-none partner-color-light-gray b-radius-5 cursor-p"
                >
                  {buttonText}
                </button>
              </div>
              <div className="a-center flex">
                <Field
                  id="allowNewsletter"
                  name="allowNewsletter"
                  type="checkbox"
                  className="cursor-p"
                />
                <label htmlFor="allowNewsletter" className="mx-1">
                  {optinText}
                </label>
              </div>
              {status === 'success' && (
                <div className="success-message-two">
                  <p>O formulário foi enviado com sucesso!</p>
                </div>
              )}
              {status === 'error' && (
                <div className="error-message-two">
                  <p>
                    Ocorreu um erro no envio do formulário, por favor tente
                    novamente
                  </p>
                </div>
              )}
              {errors.email && touched.email && (
                <p className="error-text">{errors.email}</p>
              )}
              {errors.allowNewsletter && touched.allowNewsletter && (
                <p className="error-text">{errors.email}</p>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </section>
  )
}

export default Newsletter
