import { Banner, BannerImage } from '@faststore/ui'
import './banner.scss'
import useResponsive from 'src/sdk/responsive/useResponsive'

import Section from '../Section'

interface Props {
  imageLink: string
  ImageAlt: string
  imageMobile: string
}

function BannerPhoto(props: Props) {
  const { imageLink, ImageAlt, imageMobile } = props
  const { isPortrait } = useResponsive()

  return (
    <Section>
      <Banner>
        <BannerImage>
          <img
            className="banner-photo"
            src={isPortrait ? imageMobile : imageLink}
            alt={ImageAlt}
          />
        </BannerImage>
      </Banner>
    </Section>
  )
}

export default BannerPhoto
