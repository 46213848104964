import type { ComponentType } from 'react'

import ProductShelf from './sections/ProductShelf'
import BannerPhoto from './sections/BannerPhoto'
import CardSection from './sections/CardSection'
import MiddleCarousel from './sections/MiddleCarousel'
import HighlightsSection from './sections/HighlightsSection'
import TabShelf from './sections/TabShelf'
import Newsletter from './sections/Newsletter'

const COMPONENTS: Record<string, ComponentType<any>> = {
  ProductShelf,
  BannerPhoto,
  CardSection,
  MiddleCarousel,
  HighlightsSection,
  TabShelf,
  Newsletter,
}

interface Props {
  sections?: Array<{ name: string; data: unknown }>
}

function RenderCMS({ sections }: Props) {
  return (
    <>
      {sections?.map(({ name, data }, index) => {
        const Component = COMPONENTS[name]

        if (!Component) {
          throw new Error(
            `Could not find component for block ${name}. Add a new component for this block or remove it from the CMS`
          )
        }

        return <Component key={`cms-section-${index}`} {...data} />
      })}
    </>
  )
}

export default RenderCMS
