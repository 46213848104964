import { ITEMS_PER_SECTION } from 'src/constants'

import ProductShelf from '../../sections/ProductShelf'

interface Props {
  collection: string
}

function ShelfBox(props: Props) {
  const { collection } = props

  return (
    <ProductShelf
      first={ITEMS_PER_SECTION}
      selectedFacets={[{ key: 'productClusterIds', value: collection }]}
      title=""
      suspenseData
    />
  )
}

export default ShelfBox
