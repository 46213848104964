import useResponsive from 'src/sdk/responsive/useResponsive'

import CustomCarousel from '../../ui/CustomCarousel'

type CarouselImage = {
  bannerImage: string
  imageAlt: string
  imageLink: string
  imageID: string
  bannerImageMobile: string
}

interface Props {
  images: CarouselImage[]
}

function MiddleCarousel(props: Props) {
  const { images } = props
  const { isPortrait, isTabletOrMobile } = useResponsive()
  const imageWidth = isTabletOrMobile ? `w-95-vw` : `min-width-1015-px`

  return (
    <section className="layout__content my-2">
      <CustomCarousel>
        {images.map((item) => {
          return (
            <div className={`${imageWidth}`} key={item.imageID}>
              <a className="w-100" href={item.imageLink}>
                <img
                  src={isPortrait ? item.bannerImageMobile : item.bannerImage}
                  alt={item.imageAlt}
                  width="100%"
                />
              </a>
            </div>
          )
        })}
      </CustomCarousel>
    </section>
  )
}

export default MiddleCarousel
