import './cardsection.scss'
import useResponsive from 'src/sdk/responsive/useResponsive'

import Section from '../Section'

type CardInterface = {
  cardLink: {
    cardLinkText: string
    cardLinkURL: string
  }
  cardIcon: string
  cardTitle: string
  cardText: string
}

interface Props {
  card: CardInterface[]
  cardOverflow: boolean
}

function CardSection(props: Props) {
  const { card, cardOverflow } = props
  const { isPortrait } = useResponsive()
  const mobileClass = isPortrait
    ? `cards-mobile card-overflow-mobile-${cardOverflow}`
    : `card-overflow-${cardOverflow}`

  return (
    <Section
      className={`${mobileClass} layout__content flex flex-row a-center j-center overflow-v z-9999 pb-3`}
    >
      {card.map((item, index) => {
        return (
          <article
            key={`card-article-${index}`}
            className="my-0 card-article flex flex-col a-center j-center p-3 mx-1 b-radius-5 b-shadow"
          >
            <img
              className="card-image mw-3"
              src={item.cardIcon}
              alt="Partner Card"
            />
            <h2 className="card-title t-center py-1">{item.cardTitle}</h2>
            <p className="card-text line-heigth-150 t-center py-0-5">
              {item.cardText}
            </p>
            <a
              className="card-link py-0-5 t-center"
              href={item.cardLink.cardLinkURL}
            >
              {item.cardLink.cardLinkText}
            </a>
          </article>
        )
      })}
    </Section>
  )
}

export default CardSection
