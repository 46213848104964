function RightArrow() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0101 0.462589C10.7166 0.170304 10.2418 0.17129 9.94947 0.46479C9.65718 0.75829 9.65817 1.23316 9.95167 1.52545L14.6818 6.23596H1.47894C1.06473 6.23596 0.728943 6.57175 0.728943 6.98596C0.728943 7.40018 1.06473 7.73596 1.47894 7.73596H14.722L9.95167 12.4866C9.65817 12.7789 9.65718 13.2537 9.94947 13.5472C10.2418 13.8407 10.7166 13.8417 11.0101 13.5494L17.0471 7.53745C17.1884 7.39671 17.2679 7.20547 17.2679 7.00602C17.2679 6.80657 17.1884 6.61533 17.0471 6.47459L11.0101 0.462589Z"
        fill="#142032"
      />
    </svg>
  )
}

export default RightArrow
