import type { MouseEvent } from 'react'
import { useRef, useState, useCallback, useEffect } from 'react'

import './customcarousel.scss'
import LeftArrow from '../Icon/LeftArrow'
import RightArrow from '../Icon/RightArrow'

interface StateCaroselParams {
  width_carosel?: number
  width_childrens?: number
  qnt_childrens?: number
  max_width_carousel?: number
}

const CustomCarousel: React.FC = ({ children }): JSX.Element => {
  const Carousel = useRef<HTMLUListElement>(null)

  const [stateCarosel, setStateCarousel] = useState<StateCaroselParams>()

  const handleCarousel = useCallback(() => {
    if (Carousel.current) {
      const carousel = Carousel.current

      setStateCarousel({
        ...stateCarosel,
        width_carosel: carousel.clientWidth,
        qnt_childrens: carousel.children.length,
        width_childrens: carousel.children.item(0)?.clientWidth,
        max_width_carousel:
          (carousel.children.length - 1) *
          (carousel.children.item(0)?.clientWidth ?? 1),
      })
    }
  }, [stateCarosel])

  const handleCarouselAction = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (
      Carousel?.current?.scrollLeft != null &&
      stateCarosel?.width_childrens != null
    ) {
      switch (e.currentTarget.id) {
        case 'next':
          Carousel.current.scrollLeft += stateCarosel.width_childrens

          return Carousel.current.scrollLeft

        case 'prev':
          Carousel.current.scrollLeft -= stateCarosel.width_childrens

          return Carousel.current.scrollLeft

        default:
          return null
      }
    }

    return null
  }

  useEffect(() => handleCarousel(), [handleCarousel])

  return (
    <section className="carousel-section layout__content">
      <ul ref={Carousel} className="carousel-div">
        {children}
        <div className="buttons">
          <button
            onClick={handleCarouselAction}
            id="prev"
            className="prev"
            name="Previous carousel button"
          >
            <LeftArrow />
          </button>
          <button
            onClick={handleCarouselAction}
            id="next"
            className="next"
            name="Next carousel button"
          >
            <RightArrow />
          </button>
        </div>
      </ul>
    </section>
  )
}

export default CustomCarousel
