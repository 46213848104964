import { Suspense, useState } from 'react'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'

import ShelfBox from '../../ui/ShelfBox'

type TabType = {
  title: string
  collection: string
}
interface Props {
  sectionTitle: string
  tabOne: TabType
  tabTwo: TabType
}

function TabShelf(props: Props) {
  const { sectionTitle, tabOne, tabTwo } = props
  const [activeTab, setActiveTab] = useState<string>('tab-1')

  return (
    <section className="Tabs layout__content flex flex-col j-center">
      <h2 className="partner-color-dark py-1 section-title my-1">
        {sectionTitle}
      </h2>
      <ul className="nav flex border-b-secondary">
        <li
          className={`p-1 cursor-p ${
            activeTab === 'tab-1' ? 'border-b-pink' : ''
          }`}
        >
          <button
            className="background-none b-none cursor-p"
            onClick={() => setActiveTab('tab-1')}
          >
            {tabOne.title}
          </button>
        </li>
        <li
          className={`p-1 cursor-p ${
            activeTab === 'tab-2' ? 'border-b-pink' : ''
          }`}
        >
          <button
            className="background-none b-none cursor-p"
            onClick={() => setActiveTab('tab-2')}
          >
            {tabTwo.title}
          </button>
        </li>
      </ul>
      <div className="outlet">
        {activeTab === 'tab-1' ? (
          <>
            <Suspense fallback={<ProductShelfSkeleton loading />}>
              <ShelfBox collection={tabOne.collection} />
            </Suspense>
          </>
        ) : (
          <>
            <Suspense fallback={<ProductShelfSkeleton loading />}>
              <ShelfBox collection={tabTwo.collection} />
            </Suspense>
          </>
        )}
      </div>
    </section>
  )
}

export default TabShelf
