import {
  ProductCard as UIProductCard,
  ProductCardActions as UIProductCardActions,
  ProductCardContent as UIProductCardContent,
  ProductCardImage as UIProductCardImage,
} from '@faststore/ui'
import { graphql, Link } from 'gatsby'
import { memo } from 'react'
import { Badge, DiscountBadge } from 'src/components/ui/Badge'
import { Image } from 'src/components/ui/Image'
import { useProductLink } from 'src/sdk/product/useProductLink'
import type { ReactNode } from 'react'
import type { ProductSummary_ProductFragment } from '@generated/graphql'
import './product-card.scss'

type Variant = 'wide' | 'default'

export interface ProductCardProps {
  product: ProductSummary_ProductFragment
  index: number
  bordered?: boolean
  variant?: Variant
  aspectRatio?: number
  ButtonBuy?: ReactNode
}

function ProductCard({
  product,
  index,
  variant = 'default',
  bordered = false,
  aspectRatio = 1,
  ButtonBuy,
  ...otherProps
}: ProductCardProps) {
  const {
    sku,
    description,
    isVariantOf: { name },
    image: [img],
    offers: {
      lowPrice: spotPrice,
      offers: [{ listPrice, availability }],
    },
  } = product

  const linkProps = useProductLink({ product, selectedOffer: 0, index })
  const outOfStock = availability !== 'https://schema.org/InStock'

  const truncateText =
    description.length > 110
      ? `${description.substring(0, 110)}...`
      : description

  return (
    <UIProductCard
      data-fs-product-cards
      data-fs-product-card-variant={variant}
      data-fs-product-card-bordered={bordered}
      data-fs-product-card-actionable={!!ButtonBuy}
      data-fs-product-card-sku={sku}
      {...otherProps}
      className="bg-partner-color-white w-100"
    >
      <UIProductCardImage className="w-60 mx-auto py-1">
        <Link {...linkProps} title={name}>
          <Image
            src={img.url}
            alt={img.alternateName}
            width={360}
            height={360 / aspectRatio}
            sizes="(max-width: 768px) 25vw, 30vw"
            loading="lazy"
            options={{
              fitIn: true,
            }}
          />
        </Link>
      </UIProductCardImage>
      <UIProductCardContent>
        <div>
          <h3 className="p-1 product-card-title">
            <Link
              {...linkProps}
              title={name}
              className="no-decoration product-card-title"
            >
              {name}
            </Link>
          </h3>
        </div>
        <div className="p-1">
          <p className="line-heigth-150 product-card-description flex ">
            {truncateText}
          </p>
        </div>
        {outOfStock ? (
          <Badge>Out of stock</Badge>
        ) : (
          <DiscountBadge listPrice={listPrice} spotPrice={spotPrice} />
        )}

        {!!ButtonBuy && (
          <UIProductCardActions data-fs-product-card-actions>
            {ButtonBuy}
          </UIProductCardActions>
        )}
      </UIProductCardContent>
    </UIProductCard>
  )
}

export const fragment = graphql`
  fragment ProductSummary_product on StoreProduct {
    id: productID
    slug
    sku
    brand {
      brandName: name
    }
    name
    description
    gtin

    isVariantOf {
      productGroupID
      name
    }

    image {
      url
      alternateName
    }

    brand {
      name
    }

    offers {
      lowPrice
      offers {
        availability
        price
        listPrice
        quantity
        seller {
          identifier
        }
      }
    }
  }
`

export default memo(ProductCard)
