function LeftArrow() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2689 7.02608C17.2689 7.44029 16.9331 7.77608 16.5189 7.77608L1.52893 7.77607C1.11472 7.77607 0.778933 7.44029 0.778933 7.02607C0.778934 6.61186 1.11472 6.27607 1.52893 6.27607L16.5189 6.27608C16.9331 6.27608 17.2689 6.61186 17.2689 7.02608Z"
        fill="#142032"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.04841 13.5472C7.75612 13.8407 7.28125 13.8417 6.98775 13.5494L0.950752 7.53745C0.809426 7.39671 0.72998 7.20547 0.72998 7.00602C0.72998 6.80657 0.809426 6.61533 0.950752 6.47459L6.98775 0.462589C7.28125 0.170304 7.75613 0.17129 8.04841 0.46479C8.3407 0.75829 8.33971 1.23316 8.04621 1.52545L2.54285 7.00602L8.04621 12.4866C8.33971 12.7789 8.34069 13.2537 8.04841 13.5472Z"
        fill="#142032"
      />
    </svg>
  )
}

export default LeftArrow
